.modal {
  position: relative;
  /* top: 50%; */
  transition: all 1s ease-in-out;
  /* transform: translate(-50%, -50%); */
  background-color: white;
  /* padding: 15px; */
  z-index: 1000;
  width: 750px;
  border-radius: 18px;
}

@media (max-width: 768px) {
  .modal {
    width: 330px;
  }
}

.overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
