.mySwiper {
  height: 100%;
  width: 100%;
  padding: 10px;
}

.mySwiperSlide {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
