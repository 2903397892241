.mySwiper1 {
  width: 90vw;
  height: 250px;
  /* border: 2px solid black; */
}
.mySwiperSlide1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.provideDiv {
  background: #f3f4f6;
  box-shadow: 10px 10px 20px #dbdcdd, -10px -10px 20px #ffffff;
}
